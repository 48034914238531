import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import PostListing from "../components/PostListing"
import Head from "../components/Head/Head"

const SectionStyles = styled.section`
  max-width: clamp(95%, 3vw, 80%);
  margin: 0 auto;

  h1 + p {
    font-size: clamp(2.6rem, 4vw, 3.8rem);
    line-height: 1.1;
    padding: 1rem 0 2rem;
  }
`

const PrinciplesPage = ({ data }): JSX.Element => {
  return (
    <>
      <SectionStyles>
        <Head title="Guiding principles" />
        <div className="container">
          <h1>Guiding Principles</h1>
          <p>
            These are our guiding principles. They are signposts we look to that
            help us stay on course as we do front-end development. So please, read
            through these and let the team know what you think. Agree? Let us
            know. Disagree? Let's talk about it.
          </p>
          <PostListing posts={data.allMdx.nodes} />
        </div>
      </SectionStyles>
    </>
  )
}

export default PrinciplesPage

export const PageQuery = graphql`
  query PostsQuery {
    allMdx {
      nodes {
        frontmatter {
          title
          subTitle
        }
        slug
      }
    }
  }
`
